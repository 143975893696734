import React, { useCallback, useMemo, useState } from 'react';

import Input from '~/components/Input';
import availableServices from '~/data/services';
import PricesGroup from '~/pages/cennik/components/PricesGroup';

import * as styles from './Cennik.module.scss';

const Cennik: React.FC = () => {
  const [input, setInput] = useState('');

  const services = useMemo(() => {
    return availableServices.services
      .map((s) => {
        return {
          ...s,
          pricesList: s.pricesList.filter((p) => p.title.toLowerCase().includes(input.toLowerCase())),
        };
      })
      .filter((s) => s.pricesList.length > 0);
  }, [input]);

  const handleInputChange = useCallback(
    (e) => {
      setInput(e.target.value);
    },
    [setInput],
  );

  return (
    <section className={styles.root}>
      <h1 className={styles.header}>Cennik</h1>
      <p className={styles.description}>
        Zapraszamy do zapoznania się z naszą ofertą cenową. Znajdziesz tutaj wypisane wszystkie oferowane przez nas
        usługi wraz z cenami.
      </p>
      <Input onChange={handleInputChange} className={styles.input} placeholder='Wpisz jakiej usługi szukasz' />

      <div className={styles.pricesList}>
        {services.map((s) => (
          <PricesGroup key={s.title} data={s} />
        ))}
        {services.length === 0 && <h3>Przykro nam, ale niestety nie oferujemy wyszukanej oferty.</h3>}
      </div>
    </section>
  );
};

export default Cennik;
