import cx from 'classnames';
import React from 'react';

import { Service } from '~/data/services';

import * as styles from './PricesGroup.module.scss';

export interface Props {
  className?: string;
  data: Service;
}

const PricesGroup: React.FC<Props> = (props) => {
  const classes = cx(styles.root, props.className);

  return (
    <div className={classes}>
      <div className={styles.imageContainer}>
        <img src={props.data.image} alt={props.data.title} className={styles.image} />
      </div>

      <div className={styles.content}>
        <h2 className={styles.header}>{props.data.title}</h2>

        <div className={styles.list}>
          {props.data.pricesList.map((p, idx) => (
            <div key={idx} className={styles.listElement}>
              <div className={styles.title}>{p.title}</div>
              <div className={styles.price}>{p.price}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricesGroup;
